// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portfolio-primary: mat.define-palette(mat.$green-palette);
$portfolio-accent: mat.define-palette(mat.$indigo-palette, 600);

// The warn palette is optional (defaults to red).
$portfolio-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portfolio-theme: mat.define-dark-theme((color: (primary: $portfolio-primary,
                accent: $portfolio-accent,
                warn: $portfolio-warn,
            ),
            typography: mat.define-typography-config(),
            density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($portfolio-theme);

.mdc-text-field {
    --mdc-outlined-text-field-color: #4B47FF;
    --mdc-outlined-text-field-outline-color: #4B47FF;
    --mdc-outlined-text-field-label-text-color: #4B47FF;
    // --mdc-outlined-text-field-hover-outline-width: 10px;
    --mdc-outlined-text-field-hover-label-text-color: #E61C40;
    --mdc-outlined-text-field-hover-outline-color: #1CE6B6;
    --mdc-outlined-text-field-focused-label-text-color: #007BFF !important;
    // --mdc-outlined-input-field-hover-outline-width: 10px;
    --mdc-outlined-text-field-container-shape: 10px;
    --mdc-outlined-text-field-outline-width: 1px;
}

.mat-mdc-text-field-wrapper {
    background-color: #141D2F;
    border-radius: 10px !important;
}



* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    margin: 0;
}

body {
    width: calc(100vw - (100vw - 100%));
    background-color: #141D2F;
    font-family: poppins;
    color: white;
    background-image: url(./assets/img/background/purple-shadow1.svg), url(./assets/img/background/blue-shadow2.svg), url(./assets/img/background/purple-shadow4.svg), url(./assets/img/background/blue-shadow2.svg);
    background-size: min(calc(70vh - 64px), 80vw);
    background-position: right -100px top 1040px, left -50px center, left bottom 1000px, right bottom 600px;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

a {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

button {
    cursor: pointer;
    padding: 17px 30px 17px 30px;
    border-radius: 10px;
    background-color: #00BEE8;
    border: none;
    font-size: 23px;
    color: white;
    white-space: nowrap;
    transition: transform 0.3s;
    box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.25);
    margin: 8px;
}

h1 {
    // font-size: clamp(45px, 6vw, 90px);
    font-size: clamp(38px, 6vw, 90px);
    margin-bottom: 30px;
    white-space: nowrap;
}

.logo {
    z-index: 500;
    font-weight: 700;
    span {
        font-weight: 800;
        color: #00BEE8;
    }
}

// html,
// body {
//     height: 100%;
// }

// body {
//     margin: 0;
//     font-family: Roboto, "Helvetica Neue", sans-serif;
// }

@media (max-width: 700px) {
    button {
        font-size: 16px;
    }
}